import React, { useState } from 'react';
import LazyLoad from 'react-lazyload';
import { useForm } from 'react-hook-form';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import GatsbyImage from 'gatsby-image';

import { useGraphQL } from '../../hooks';

const SubscribeSection = () => {
  const { subscribeSectionImage } = useGraphQL();

  const { register, handleSubmit } = useForm();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [message, setMessage] = useState('');

  function onSubmit(data, event) {
    event.preventDefault();

    setIsSubmitting(true);

    addToMailchimp(data.email_address)
      .then(({ msg, result }) => {
        if (result !== 'success') {
          throw msg;
        }
        setMessage(msg);
      })
      .catch((err) => {
        setMessage(err);
        setIsSubmitting(false);
      });
  }

  return (
    <article className="relative overflow-hidden bg-white">
      <div className="flex flex-wrap w-full mx-auto">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3404.241838775774!2d152.89910671514846!3d-31.43500848139793!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b9dff233bdbec93%3A0xe17ce2081a3c3820!2sShop+3%2F158+Gordon+St%2C+Port+Macquarie+NSW+2444!5e0!3m2!1sen!2sau!4v1557115024048!5m2!1sen!2sau"
          className="w-full lg:w-1/2"
          frameBorder={0}
          style={{ border: 0 }}
          title="location"
          allowFullScreen
        />
        <div className="relative w-full py-12 bg-white lg:py-24 lg:pl-24 lg:w-1/2">
          {/* <svg
            aria-hidden
            focusable={false}
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            className="absolute inset-y-0 right-0 hidden w-48 h-full transform translate-x-1/2 text-slategreen lg:block"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg> */}

          <div className="relative max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
            <h2 className="text-5xl font-bold leading-none text-center text-slategreen lg:text-left">
              Follow us for
              <br /> the latest news
            </h2>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="relative max-w-sm mx-auto mt-12 lg:max-w-none"
            >
              <label className="flex flex-col" htmlFor="email_address">
                {/* <span className="mb-2 text-xs font-bold tracking-wider text-white uppercase">
                  Email address:
                </span> */}
                <input
                  ref={register({ required: true })}
                  required
                  id="email_address"
                  name="email_address"
                  type="email"
                  placeholder="Email"
                  className="w-full px-3 py-2 leading-tight bg-white border-2 rounded-none appearance-none lg:max-w-sm hover:bg-gray-100 focus:outline-none focus:border-green-500"
                />
              </label>
              <button
                type="submit"
                disabled={isSubmitting}
                className={`px-4 py-2 mt-6 text-xs font-semibold leading-none tracking-wider text-white bg-green-500 cursor-pointer hover:bg-green-400 ${
                  isSubmitting ? 'opacity-50 cursor-wait' : ''
                }`}
              >
                Submit
              </button>

              {message && (
                <div className="inset-x-0 mt-4 text-sm tracking-wider break-words">
                  <p>{message}</p>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </article>
  );
};

export default SubscribeSection;
