import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import { FaAngleDoubleRight } from 'react-icons/fa';

import { useGraphQL } from '../../hooks/useGraphQL';

const AboutSection = () => {
  const { aboutImage } = useGraphQL();
  return (
    <div
      id="about"
      className="flex flex-wrap w-full mx-auto my-8 max-w-1920px md:mb-16"
    >
      <div className="relative w-full p-4 sm:px-12 lg:pl-24 md:w-1/2">
        <Image className="h-full" fluid={aboutImage.childImageSharp.fluid} />
      </div>
      <div className="flex items-center justify-center w-full p-4 sm:px-12 md:w-1/2">
        <div className="flex flex-col items-center justify-center max-w-lg">
          <Link
            className="w-full mb-4 text-4xl font-bold leading-none text-slategreen"
            to="/about"
          >
            <h2>About</h2>
          </Link>
          <div className="w-full pl-4 text-gray-600 border-l-4 border-green-500">
            <p>
              Morr Homes and Projects is a progressive building company based in
              Port Macquarie. Our professional team share a collective passion
              for planning with purpose and building with pride. We specialise
              in multi-residential, commercial and niche home projects. We have
              over 30 years’ experience to draw on as regional specialists in
              building design and construction.
            </p>
            <p className="flex items-center mt-4 font-bold text-green-500 uppercase">
              <Link className="flex items-center" to="/about">
                <span>Read more </span>
                <FaAngleDoubleRight className="ml-1 text-xs" />
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
