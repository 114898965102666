import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import Carousel from 'nuka-carousel';

import GatsbyImage from 'gatsby-image';
import { useTailwindConfig, useMediaQuery } from '../../hooks';

const AwardsSectionCarousel = () => {
  const tailwindConfig = useTailwindConfig();

  const isMedium = useMediaQuery(
    `(max-width: ${tailwindConfig.theme.screens.md})`
  );

  const isXL = useMediaQuery(`(max-width: ${tailwindConfig.theme.screens.xl})`);

  let slidesToShow = 5;
  if (isXL) slidesToShow = 3;
  if (isMedium) slidesToShow = 1;

  const { awards } = useStaticQuery(graphql`
    {
      awards: allFile(filter: { sourceInstanceName: { eq: "awards" } }) {
        nodes {
          id
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `);

  return (
    <Carousel
      autoplay
      autoplayInterval={5000}
      cellAlign="center"
      dragging
      easing="easeCubicInOut"
      enableKeyboardControls
      slidesToShow={slidesToShow}
      swiping
      wrapAround
      renderCenterLeftControls={({ previousSlide }) => (
        <button
          aria-label="Next slide"
          className="p-4"
          onClick={previousSlide}
          type="button"
        >
          <FaAngleDoubleLeft className="text-xl text-black" />
        </button>
      )}
      renderCenterRightControls={({ nextSlide }) => (
        <button
          aria-label="Next slide"
          className="p-4"
          onClick={nextSlide}
          type="button"
        >
          <FaAngleDoubleRight className="text-xl text-black" />
        </button>
      )}
      renderBottomCenterControls={null}
      className="px-12 mt-6 md:my-12"
    >
      {awards.nodes.map((award) => (
        <GatsbyImage
          key={award.id}
          fluid={award.childImageSharp.fluid}
          imgStyle={{ objectFit: 'contain' }}
          className="h-48"
        />
      ))}
    </Carousel>
  );
};

export default AwardsSectionCarousel;
