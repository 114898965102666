import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import { FaPlus, FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import Carousel from 'nuka-carousel';

import { useGraphQL } from '../../hooks/useGraphQL';

const LatestProjects = () => {
  const data = useGraphQL();

  return (
    <Carousel
      autoplay
      autoplayInterval={5000}
      cellAlign="center"
      dragging
      easing="easeCubicInOut"
      enableKeyboardControls
      swiping
      wrapAround
      renderCenterLeftControls={({ previousSlide }) => (
        <button
          aria-label="Previous slide"
          className="p-4 outline-none focus:shadow-outline"
          onClick={previousSlide}
          type="button"
        >
          <FaAngleDoubleLeft className="text-xl text-white" />
        </button>
      )}
      renderCenterRightControls={({ nextSlide }) => (
        <button
          aria-label="Next slide"
          className="p-4"
          onClick={nextSlide}
          type="button"
        >
          <FaAngleDoubleRight className="text-xl text-white" />
        </button>
      )}
      renderBottomCenterControls={null}
    >
      {data.allSanityProject.nodes.map((project) => (
        <div key={project._id} style={{ height: `360px` }}>
          {project.mainImage ? (
            <Image
              className="h-full opacity-75"
              fluid={project.mainImage.asset.fluid}
              alt=""
            />
          ) : (
            ''
          )}

          <div className="absolute inset-0 flex flex-wrap content-between p-4 text-xl">
            <p className="flex justify-end w-full">
              <Link to={`/projects/${project.slug.current}/`}>
                <FaPlus />
              </Link>
            </p>
            <Link
              to={`/projects/${project.slug.current}/`}
              className="flex justify-center w-full font-bold leading-none uppercase"
            >
              <h3>{project.title}</h3>
            </Link>
          </div>
          {project.awardBadge ? (
            <div className="absolute bottom-0 right-0 z-10 w-24 h-24 mb-2 mr-3 rounded-full">
              <Image
                fluid={project.awardBadge.asset.fluid}
                alt="HIA Award Winner."
              />
            </div>
          ) : (
            ''
          )}
        </div>
      ))}
    </Carousel>
  );
};

export default LatestProjects;
