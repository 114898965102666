import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { FaAngleDoubleDown } from 'react-icons/fa';
import { FacebookIcon, InstagramIcon } from '../vectors';

import { useGraphQL } from '../../hooks';
import Logo from '../../images/assets/logo-vertical-lockup.svg';

const NewHero = () => {
  const { heroImage } = useGraphQL();
  return (
    <div className="relative">
      <Image
        style={{ height: `50vh`, minHeight: `22rem` }}
        fluid={heroImage.childImageSharp.fluid}
        alt="Aerial shot looking down at houses in various stages of construction"
        loading="eager"
      />
      <div className="absolute inset-0 flex flex-col w-full p-4 px-12 mx-auto font-sans font-bold text-green-500 max-w-1920px sm:px-12 lg:px-56">
        <div className="flex items-center justify-between flex-1 mt-auto">
          <div className="text-5xl leading-none">
            <span className="inline-block px-3 py-1 my-1 bg-white">Plan.</span>
            <br />
            <span className="inline-block px-3 py-1 my-1 bg-white">Build.</span>
            <br />
            <span className="inline-block px-3 py-1 my-1 bg-white">
              Deliver.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewHero;
