import React from 'react';
import { Link } from 'gatsby';

import Carousel from '../Carousels/LatestProjectsSectionCarousel';
import triangle from '../../images/assets/triangle.svg';
import vertline from '../../images/assets/vertline.svg';

const ProjectSection = () => (
  <div
    id="projects"
    className="relative px-4 py-16 bg-green-500 sm:px-12 xl:px-24"
  >
    <div className="flex flex-wrap-reverse w-full mx-auto text-white max-w-1920px">
      <div className="flex justify-center w-full lg:w-1/2">
        <div className="latest-projects-carousel" style={{ maxWidth: 480 }}>
          <Carousel />
        </div>
      </div>
      <div className="flex items-center justify-start w-full p-4 lg:w-1/2">
        <img
          className="hidden transform -translate-x-12 xl:block"
          src={triangle}
          alt="triangle"
        />
        <img
          className="hidden transform -translate-x-12 xl:block"
          src={vertline}
          alt="vertline"
        />
        <h2 className="mb-16 text-5xl font-bold leading-none lg:mb-0">
          <Link to="/projects">
            <span>View our </span>
            <br className="hidden lg:block" />
            <span>latest projects </span>
          </Link>
        </h2>
      </div>
    </div>
  </div>
);

export default ProjectSection;
