import React from 'react';

import Carousel from '../Carousels/AwardsSectionCarousel';

const AwardsSection = () => (
  <div id="awards" className="relative px-4 py-16 sm:px-12 xl:px-24">
    <div className="w-full mx-auto max-w-1920px">
      <h2 className="text-5xl font-bold leading-none text-center text-slategreen">
        Our Latest Awards{' '}
      </h2>
      <Carousel />
      <style>{`.slider-frame { padding-top: 0 !important; padding-bottom: 0 !important }`}</style>
    </div>
  </div>
);

export default AwardsSection;
