import React from 'react';
import { Link } from 'gatsby';
import Form from '../Form';
import { useGraphQL } from '../../hooks/useGraphQL';

const ContactSection = () => {
  const data = useGraphQL();
  return (
    <div
      id="contact"
      className="flex flex-wrap w-full p-6 mx-auto sm:p-12 lg:p-24 max-w-1920px bg-othergray"
    >
      <div className="flex items-center justify-center w-full py-10 bg-white sm:px-12 md:w-1/3">
        <div className="flex flex-col items-center justify-center max-w-lg md:pl-12">
          <Link
            className="w-full mb-4 text-4xl font-bold leading-none text-slategreen"
            to="/contact"
          >
            <h2>Contact</h2>
          </Link>
          <div className="pl-4 border-l-4 border-green-500">
            <p className="mb-4">
              Ready to get your building project rolling but need to know more?
              Let’s start the conversation here.
            </p>
            <p className="mb-4">
              Send us your questions. And we’ll get you the answers!
            </p>
            <p className="font-bold text-slategreen">
              Send email to{' '}
              <a
                className="hover:text-green-500"
                href={`mailto:${data.site.siteMetadata.email}`}
              >
                {data.site.siteMetadata.email}
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center w-full p-4 bg-white sm:px-4 xl:px-24 md:w-2/3">
        <div className="w-full px-4 border-l-4 border-transparent">
          <h3 className="w-full max-w-lg mb-3 text-lg font-bold text-slategreen">
            Enquiry
          </h3>
          <Form />
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
