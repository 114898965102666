import React from 'react';
import { navigate } from 'gatsby-link';

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <form
        data-netlify-honeypot="bot-field"
        data-netlify="true"
        onSubmit={this.handleSubmit}
        action="/success/"
        className="w-full mx-auto text-base md:flex"
        method="post"
        name="contact"
      >
        <div className="w-full md:w-1/2">
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="contact" />
          <div hidden>
            <label htmlFor="bot-field">
              Don’t fill this out:{' '}
              <input
                id="bot-field"
                name="bot-field"
                onChange={this.handleChange}
              />
            </label>
          </div>

          {/* Name */}
          <div className="mb-6">
            <label className="flex flex-col" htmlFor="name">
              <input
                className="w-full px-3 py-2 leading-tight text-gray-700 bg-white border-2 rounded-none appearance-none hover:bg-gray-100 focus:outline-none focus:border-green-500"
                id="name"
                name="name"
                placeholder="Name"
                onChange={this.handleChange}
                required
                type="text"
              />
            </label>
          </div>

          {/* Email address */}
          <div className="mb-6">
            <label className="flex flex-col" htmlFor="email">
              <input
                className="w-full px-3 py-2 leading-tight text-gray-700 bg-white border-2 rounded-none appearance-none hover:bg-gray-100 focus:outline-none focus:border-green-500"
                id="email"
                name="email"
                placeholder="Email"
                onChange={this.handleChange}
                required
                type="email"
              />
            </label>
          </div>

          {/* Phone number */}
          <div className="mb-6">
            <label className="flex flex-col" htmlFor="phone">
              <input
                className="w-full px-3 py-2 leading-tight text-gray-700 bg-white border-2 rounded-none appearance-none hover:bg-gray-100 focus:outline-none focus:border-green-500"
                id="phone"
                name="phone"
                required
                placeholder="Phone Number"
                onChange={this.handleChange}
                type="text"
              />
            </label>
          </div>
        </div>
        <div className="w-full md:w-1/2 md:pl-12">
          {/* Message */}
          <div className="mb-6">
            <label className="flex flex-col" htmlFor="message">
              <textarea
                className="w-full px-3 py-2 leading-tight text-gray-700 bg-white border-2 rounded-none appearance-none hover:bg-gray-100 focus:outline-none focus:border-green-500"
                id="message"
                name="message"
                placeholder="Message"
                onChange={this.handleChange}
                required
                rows={5}
              />
            </label>
          </div>

          <div className="mb-6 -mt-2 ">
            {/* Submit */}
            <button
              className="px-8 py-2 text-xs font-semibold leading-none tracking-wider text-white uppercase bg-green-500 cursor-pointer hover:bg-green-400"
              type="submit"
            >
              Send
            </button>
          </div>
        </div>
      </form>
    );
  }
}
