import React from 'react';

import { graphql, Link, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';

import { FaAngleDoubleRight } from 'react-icons/fa';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import NewHero from '../components/NewHero';
import AboutSection from '../components/AboutSection';
import ProjectsSection from '../components/ProjectsSection';
import ContactSection from '../components/ContactSection';
import AwardsSection from '../components/AwardsSection';
import SubscribeSection from '../components/SubscribeSection';
import Location from '../components/Location';
import horizline from '../images/assets/horizline.svg';
import downtriangle from '../images/assets/downtriangle.svg';

const IndexPage = () => {
  const {
    site,
    servicesHero,
    multiResidential,
    customHomes,
    commercial,
    designConstruct,
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          phone
          phoneFormatted
        }
      }
      servicesHero: file(relativePath: { regex: "/services.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      multiResidential: file(
        sourceInstanceName: { eq: "hero-images" }
        relativePath: { eq: "foreshore-ave.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      customHomes: file(
        sourceInstanceName: { eq: "hero-images" }
        relativePath: { eq: "matthew-flinders.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      commercial: file(
        sourceInstanceName: { eq: "hero-images" }
        relativePath: { eq: "discovery-early-learning.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      designConstruct: file(
        sourceInstanceName: { eq: "hero-images" }
        relativePath: { eq: "ascot-villas.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const services = [
    {
      title: 'Multi Residential and Community Facilities',
      image: multiResidential.childImageSharp.fluid,
      cta: {
        label: 'View More',
        slug: '/projects/foreshore-ave/',
        filter: 'Multi/Community',
      },
      copy: `<p>Developing well-designed, functional and affordable low to medium density housing. Buildings that are sensitive to the needs of the community. And give great consideration to who will live in them.</p>`,
    },
    {
      title: 'Custom Homes',
      image: customHomes.childImageSharp.fluid,
      cta: {
        label: 'View More',
        slug: '/projects/matthew-flinders/',
        filter: 'Custom home',
      },
      copy: `<p>Looking to build your dream home? The opportunity of bringing your vision to life is an exciting time. But having the right team for a smooth journey is essential. Whether you bring your own plans, or we connect you with an architect.</p>`,
    },
    {
      title: 'Commercial Building Projects',
      image: commercial.childImageSharp.fluid,
      cta: {
        label: 'View More',
        slug: '/projects/discovery-early-learning/',
        filter: 'Commercial',
      },
      copy: `<p>Multi-Residential Projects and Community Facilities Creating shared living environments is what we do best. Developing well-designed, functional and affordable low to medium density housing.</p>`,
    },
    {
      title: 'Design and Construct',
      image: designConstruct.childImageSharp.fluid,
      cta: {
        label: 'View More',
        slug: '/projects/ascot-villas/',
        filter: 'Design & Construct',
      },
      copy: `<p>As a design &amp; construct builder, we offer a complete home building package. Our design and construct method is the perfect building solution for many clients. With one point of contact from start to finish.</p>`,
    },
  ];
  return (
    <>
      <SEO title="Home" />
      <Layout>
        <NewHero />
        <AwardsSection />
        <ProjectsSection />
        <Services services={services} />
        <AboutSection />
        <ContactSection />
        <SubscribeSection />
        {/* <Location /> */}
      </Layout>
    </>
  );
};

function NewServices(services) {
  return (
    <section
      id="services"
      className="flex flex-col p-4 mb-8 overflow-hidden md:mb-16 sm:px-12 lg:px-24 bg-othergray"
    >
      <h2 className="pt-24 pb-12 text-5xl font-bold leading-none text-center text-slategreen">
        Our Services
      </h2>
      <div className="grid w-full gap-12 pb-24 mx-auto max-w-7xl xl:grid-cols-2">
        {services.services.map((service) => (
          <div
            key={service.cta.slug}
            className="flex flex-col justify-between xl:flex-row"
          >
            <div className="w-full mb-6 text-gray-600">
              <Link
                to={`/projects?filter=${encodeURIComponent(
                  service.cta.filter.toLowerCase()
                )}`}
                className="block bg-black"
              >
                <Image className="w-full" fluid={service.image} />
              </Link>
            </div>
            <div className="md:pl-6">
              <h2 className="w-full my-8 text-2xl font-bold leading-none tracking-wider text-slategreen">
                {service.title}
              </h2>
              <div
                dangerouslySetInnerHTML={{ __html: service.copy }}
                className="leading-loose prose text-slategreen "
              />
              <Link
                to={`/projects?filter=${encodeURIComponent(
                  service.cta.filter.toLowerCase()
                )}`}
                className="flex items-center max-w-sm font-bold tracking-wide uppercase text-slategreen"
              >
                <span>View More</span>
                <FaAngleDoubleRight className="ml-1 text-xs" />
              </Link>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

function Services(services) {
  return (
    <section
      id="services"
      className="flex flex-col p-4 mb-8 overflow-hidden md:mb-16 sm:px-12 lg:px-24 bg-othergray"
    >
      <h2 className="pt-24 pb-12 text-5xl font-bold leading-none text-center text-slategreen">
        Our Services
      </h2>
      <div className="grid w-full gap-12 pb-24 mx-auto max-w-7xl md:grid-cols-2 xl:grid-cols-4">
        {services.services.map((service) => (
          <div key={service.cta.slug} className="flex flex-col justify-between">
            <div className="w-full mb-6 text-gray-600">
              <Link
                to={`/projects?filter=${encodeURIComponent(
                  service.cta.filter.toLowerCase()
                )}`}
                className="block bg-black"
              >
                <Image fluid={service.image} />
              </Link>
              <img className="w-full mt-4" src={horizline} alt="vertline" />
              <img
                className="transform translate-x-8 -translate-y-1"
                src={downtriangle}
                alt="triangle"
              />
              <h2 className="w-full my-8 text-2xl font-bold leading-none tracking-wider text-slategreen">
                {service.title}
              </h2>
              <div
                dangerouslySetInnerHTML={{ __html: service.copy }}
                className="leading-loose prose text-slategreen "
              />
            </div>
            <div>
              <Link
                to={`/projects?filter=${encodeURIComponent(
                  service.cta.filter.toLowerCase()
                )}`}
                className="flex items-center max-w-sm font-bold tracking-wide uppercase text-slategreen"
              >
                <span>View More</span>
                <FaAngleDoubleRight className="ml-1 text-xs" />
              </Link>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default IndexPage;
